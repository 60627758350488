// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

require('cookie-notice/dist/cookie.notice.min.js');

new cookieNoticeJS({
    // Shows the "learn more button (default=false)
    'learnMoreLinkEnabled': false,
    'expiresIn': 30,
    'fontFamily': 'inherit',
    'fontSize': '0.8rem',
    'buttonBgColor': '#f3f3f3',
    'buttonTextColor': '#000',
    'noticeBgColor': '#000',
    'noticeTextColor': '#fff',
});
