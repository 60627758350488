// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
// import "vanilla-cookieconsent/dist/cookieconsent.css" // (Something is broken here)
import * as CookieConsent from "vanilla-cookieconsent";

CookieConsent.run({
    revision: 1,
    guiOptions: {
        consentModal: {
            layout: "box inline",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {},
        analytics: {}
    },
    language: {
        default: "nl",
        autoDetect: "browser",
        translations: {
            nl: {
                consentModal: {
                    title: "Geachte Bezoeker",
                    description: "Onze website maakt gebruik van cookies. Door op 'Accepteren' te klikken, ga je akkoord met het gebruik van alle cookies. Je kunt ook zelf een keuze maken door de cookies handmatig in te stellen.",
                    acceptAllBtn: "Accepteren",
                    showPreferencesBtn: "Handmatig Instellen",
                    // footer: "<a href=\"\">Privacy Policy</a>"
                },
                preferencesModal: {
                    title: "Cookie Consent",
                    acceptAllBtn: "Alles Accepteren",
                    acceptNecessaryBtn: "Alles Weigeren",
                    savePreferencesBtn: "Opslaan",
                    closeIconLabel: "Sluiten",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Deze website maakt gebruik van cookies",
                            description: "Deze website maakt gebruik van cookies. Door op 'Accepteren' te klikken, ga je akkoord met het gebruik van alle cookies. Je kunt ook zelf een keuze maken door de cookies handmatig in te stellen."
                        },
                        {
                            title: "Functionele Cookies <span class=\"pm__badge\">Altijd Actief</span>",
                            description: "Om deze website goed te laten werken plaatsen we functionele cookies. Zonder deze cookies zou de website niet goed werken.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domein',
                                    desc: 'Beschrijving',
                                },
                                body: [
                                    {
                                        name: 'PHPSESSID',
                                        domain: 'speetjens.net',
                                        desc: 'Standaard cookie die door PHP wordt gebruikt om sessies te beheren.',
                                    },
                                    {
                                        name: 'cc_cookie',
                                        domain: 'speetjens.net',
                                        desc: 'Cookie voor het onthouden van de gekozen cookie voorkeuren.',
                                    },
                                ],
                            },
                        }
                    ]
                }
            }
        }
    }
});


